import React from 'react'
import { Link } from 'gatsby'
import { getAssetUrl } from '../services/cdn'
import { Header, Section } from './Blocks'
import { BranchesMap, Container as BlockContainer } from './Blocks'
import Contact from './Forms/Contact'
import { Container, Row, Col } from 'reactstrap'

const Footer = () => {
    return (
        <footer>
            <BlockContainer 
                padding={5} 
                className="text-white"
                style={{
                    backgroundPosition: '100% 10%',
                    backgroundSize: 'cover, cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('${getAssetUrl('app/SIDC-Koopinoy-Experience.jpg')}')`,
                }}
            >
                <Container>
                    <Row>
                        <Col md="8" lg="6" className="mr-auto text-left text-white">
                            <p className="lead text-white">"Ipinagmamalaki ko ang SIDC sapagkat ito ay isa sa pinakamatatag at isa sa pinakamalaking kooperatiba sa Pilipinas. Madami akong natatanggap sa SIDC tulad ng patronage refund taon-taon, cash gift, cash incentive at madaming kaalaman sa pagdalo sa mga seminar.</p>
                            <p className="lead text-white">Sa mga hindi pa kasapi ng SIDC, kayo ay aking hinihikayat na maging kasapi ng aming kooperatiba, upang matulungan din kayong umunlad katulad ko."</p>
                            <p className="text-info"><b>Crisanta A. Colis</b><br /><small>KooPinoy Service Availer</small></p>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
            {/* <BlockContainer padding={4}>
                <Container>
                    <Row>
                    <Col md="12">
                        <h1 className="text-center">Learn about our great services</h1>
                    </Col>
                    </Row>
                    <Row>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Programs</b></h2>
                            <p>A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                            <p className="mb-0 lead text-right"> <Link to="/about">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Deposits</b></h2>
                            <p>I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now.</p>
                            <p className="mb-0 lead text-right"> <Link to="/partnering">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Loans</b></h2>
                            <p>I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me.</p>
                            <p className="mb-0 lead text-right"> <Link to="/partnering">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Violated Objects</b></h2>
                            <p>Like Veterenary Services, educational Services. entire soul, like these sweet mornings of spring which I enjoy with my whole heart.</p>
                            <p className="mb-0 lead text-right"> <Link to="/">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Promotions</b></h2>
                            <p>I should be incapable of drawing a single stroke at the present moment; and yet I feel that I never was a greater artist than now.</p>
                            <p className="mb-0 lead text-right"> <Link to="/">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" md="6" className="p-3">
                        <Card>
                        <CardBody className="p-4">
                            <h2><b>Another Teaser</b></h2>
                            <p>I throw myself down among the tall grass by the trickling stream; and, as I lie close to the earth, a thousand unknown plants are noticed by me.</p>
                            <p className="mb-0 lead text-right"> <Link to="/downloads">Read more</Link> </p>
                        </CardBody>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </BlockContainer> */}
            <Section>
                <Container>
                    <Header underlined={false} title="SIDC KOOPINOY BRANCHES" />
                </Container>
            </Section>
            <BranchesMap />
            <div className="py-3" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 p-4">
                            <h1>Get in contact to us</h1>
                            <p>Need general help on Membership or our Product &amp; Services, send Feedback or just want to send us
                                a message -please use form below.&nbsp;<br /></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 p-4">
                            <img className="img-fluid d-block" alt="SIDC FULL LOGO FOOTER"
                                src={getAssetUrl("app/SIDC-FULL-LOGO-Footer.jpg")} />
                            <p className="lead mt-3">General Contact</p>
                            <p>+ 63 (0)300 4058</p>
                            <p>info@sidc.coop</p>
                            <Link to="/terms-and-condition">Terms and Condition</Link>
                        </div>
                        <div className="col-md-7 p-4">
                            <h5 className="mb-3">Please leave your message here</h5>
                            <Contact />
                        </div>
                    </div>
                </div>
            </div>
            <BlockContainer center={false} padding={0} id="footer">
                <Container>
                    <Row>
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>SIDC.COOP</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_MAIN}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_MAIN + "/about"}>About SIDC</a></li>
                                <li><a href={process.env.GATSBY_SITE_MAIN + "/culture"}>SIDC Culture</a></li>
                                <li><a href={process.env.GATSBY_SITE_MAIN + "/membership"}>Membership</a></li>
                            </ul>
                        </Col>
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Agriculture</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE + '/about'}>About</a></li>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE + '/partnering'}>Partnering</a></li>
                                <li><a href={process.env.GATSBY_SITE_AGRICULTURE + '/inquire'}>Inquire</a></li>
                            </ul>
                        </Col>
                        <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Consumer Retailing</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_CONSUMER}>Home</a></li>
                                {/* <li><a href={process.env.GATSBY_SITE_CONSUMER + '/shops'}>Shops</a></li> */}
                                <li><a href={process.env.GATSBY_SITE_CONSUMER + '/partnering'}>Partnering</a></li>
                                <li><a href={process.env.GATSBY_SITE_CONSUMER + '/inquire'}>Inquire</a></li>
                            </ul>
                        </Col>
                        {/*<Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Sorosoro Springs</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_ECO}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/rooms'}>Rooms</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/facilities'}>Facilities</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/dining'}>Dining</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/events'}>Events</a></li>
                                <li><a href={process.env.GATSBY_SITE_ECO + '/reservation'}>Reservation</a></li>
                            </ul>
                        </Col>
                         <Col md="3" sm="6" className="p-3">
                            <h4 className="my-3"><b>Koopinoy</b></h4>
                            <ul>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY}>Home</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/programs'}>Programs</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/deposits'}>Deposits</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/loans'}>Loans</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/inquire'}>Inquire</a></li>
                                <li><a href={process.env.GATSBY_SITE_KOOPINOY + '/#contact'}>Contact</a></li>
                            </ul>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md="12">
                            <p className="text-center my-3">Copyright 2019 Sorosoro Ibaba Development Cooperative (SIDC)</p>
                        </Col>
                    </Row>
                </Container>
            </BlockContainer>
        </footer>
    )
}

export default Footer