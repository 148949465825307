import React from 'react'

const Header = ({ title, headerNormal = false, children, underlined = true }) => {
    return (
        <div className="block title-header">
            <h1 className={headerNormal ? 'normal': ''}>{ title }</h1>
            { underlined && <span className="underscore"></span> }
            <p>{ children }</p>
        </div>
    )
}

export default Header