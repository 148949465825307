import React from 'react'
import { getAssetUrl } from '../../services/cdn'

const Features = ({ title, children, isHtml = false, iconClass, primary = false, src = null }) => {
    return (
        <div className="block benefit">
            {
                src === null ? 
                    <i className={`d-block ${iconClass} mb-2 text-${primary ? 'primary' : 'muted'}`}></i>
                    :
                    <img src={getAssetUrl(src)} alt={title} height="70px" />
            }
            <h4> <b>{ title }</b> </h4>
            { isHtml && children }
            { !isHtml && <p>{ children }</p> }
        </div>
    )
}

export default Features