export const getDocumentUrl = (fileName) => {
    let cdn = process.env.GATSBY_CDN;

    // makes sure that cdn url would be not having the '/' in the end of the string
    if (cdn.substr(cdn.length - 1) === '/') {
        cdn = cdn.slice(0, cdn.length - 1)
    }

    // makes sure that fileName url would have the '/' in the start of the string
    if (fileName.substr(0) !== '/') {
        fileName = '/' + fileName;    
    }

    return cdn + '/documents' +  fileName
}

export const getAssetUrl = (fileName) => {
    if (!fileName) return null

    let cdn = process.env.GATSBY_CDN

    // makes sure that cdn url would not be having the '/' in the end of the string
    if (cdn.substr(cdn.length - 1) === '/') {
        cdn = cdn.slice(0, cdn.length - 1)
    }

    // makes sure that fileName url would have the '/' in the start of the string
    if (fileName.substr(0) !== '/') {
        fileName = '/' + fileName;    
    }

    return cdn + '/assets' + fileName
}