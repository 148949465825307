import React from 'react'
import { Container } from 'reactstrap'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const branches = {
    //SIDC Office
 'Main Office, Landas ng Panata, Sales Outlet': [13.8107, 121.0896],
 'Sorosoro Springs': [13.8064,121.0982],
 'Sales Outlet': [14.5468,121.1815],
 //KooPinoy Branches
 'KooPinoy SIDC Savings and Loans - Ibaan' :	[13.819756, 121.133425] ,
 'KooPinoy SIDC Savings and Loans - Pallocan' :	[13.753890, 121.066712] ,
 'KooPinoy SIDC Savings and Loans - Lipa' :	[13.938767, 121.154265] ,
 'KooPinoy SIDC Savings and Loans - Bolbok' :	[13.773091, 121.048257] ,
 'KooPinoy SIDC Savings and Loans - San Jose' :	[13.879125, 121.103360] ,
 'Koopinoy SIDC Savings and Loans - San Pablo, Laguna' :	[14.071646, 121.325295] ,
 'Koopinoy SIDC Savings and Loans - Tanauan' :	[14.080840, 121.151845] ,
 'Koopinoy SIDC Savings and Loans - Calaca' :	[13.929132, 120.813224] ,
 'Koopinoy SIDC Savings and Loans - Alaminos' :	[14.065014, 121.246019] ,
 'Koopinoy SIDC Savings & Loans - San Juan' :	[13.824756, 121.395827] ,
 'KooPinoy SIDC Savings and Loans - Alabat' :	[14.101737, 122.012696] ,
 'KooPinoy SIDC Savings and Loans - Candelaria' :	[13.927409, 121.426157] ,
 'KooPinoy SIDC Savings and Loans - Tanay' :	[14.494107, 121.288660] ,
 'KooPinoy SIDC Savings and Loans - Palawan' :	[9.269608, 118.407762] ,
 'KooPinoy SIDC Savings and Loans - Bongabong' :	[12.748654, 121.489352] ,
 'KooPinoy SIDC Savings and Loans - Bansud, Oriental Mindoro' :	[12.860442, 121.456289] ,
 'SIDC Agritrading and Financial (KooPinoy) - Calapan' :	[13.366664, 121.168600] ,
 'SIDC Agritrading and Financing (KooPinoy) - Victoria, Oriental Mindoro' :	[13.187046, 121.275558] ,
 'KooPinoy SIDC Savings and Loans - Matungao, Oriental Mindoro' :	[13.096976, 121.389094] ,
 'KooPinoy SIDC Savings and Loans - Pinamalayan, Oriental Mindoro' :	[13.043810, 121.469598] ,
 'SIDC Agritrading and Financing (KooPinoy) - Tigaon' :	[13.627252, 123.494975] ,
 'SIDC Agritrading and Financing(KooPinoy) - Calabanga' :	[13.708428, 123.216311] ,
 'SIDC Agritrading and Financing(KooPinoy) - Polangui, Albay ' :	[13.290710, 123.492802] ,
 'KooPinoy SIDC Savings and Loans - La Purisima, Pili' :	[13.558541, 123.281830] ,
 'KooPinoy SIDC Savings and Loans - Jaen. Nueva Ecija' :	[15.340650, 120.909242] ,
 'KooPinoy SIDC Savings and Loans - Zaragoza, Nueva Ecija' :	[15.448739, 120.794300] ,
 'KooPinoy SIDC Savings and Loans - San Leonardo, Nueva Ecija' :	[15.365651, 120.963899] ,
 'SIDC Agri Trading and Financing(KooPinoy) - Guimba, Nueva Ecija' :	[15.654925, 120.779513] ,
 'SIDC Agri Trading and Financing(KooPinoy) - Talavera, Nueva Ecija' :	[15.707767, 120.903774] ,
 'SIDC Agri Trading and Financing(KooPinoy) - Mayantoc, Tarlac' :	[15.620806, 120.381004] ,
 'SIDC Agri Trading and Financing(KooPinoy) - Bacolod' :	[10.604620, 122.920939] ,
 'KooPinoy SIDC Savings and Loans - President Roxas, Capiz' :	[11.431812, 122.927711] ,
 'KooPinoy SIDC Savings and Loans - Jamindan, Capiz' :	[11.410281, 122.511004] ,
 'KooPinoy SIDC Savings and Loans - Makato, Aklan' :	[11.705778,122.312353] ,
 'KooPinoy SIDC Savings and Loans - Roxas City, Capiz' :	[11.568584, 122.754062] ,
 'KooPinoy SIDC Savings and Loans - Tapaz, Capiz' :	[11.264097, 122.534164] ,
 'KooPinoy SIDC Savings and Loan  - Alangilan, Batangas' :	[13.778667, 121.067083] ,
 'KooPinoy SIDC Savings and Loan  - Talisay' :	[14.094483, 121.040659] ,
 'KooPinoy SIDC Savings and Loan  - San Mariano, San Pascual' :	[13.849011, 121.048539] ,
 'KooPinoy SIDC Savings and Loan  - Tiaong' :	[13.957179344787916, 121.32071182178612] ,
 'KooPinoy SIDC Savings and Loan  - Antipolo Del Sur, Lipa' :	[13.914369, 121.182976] ,
 'KooPinoy SIDC Savings and Loan  - Mahabang Parang, Batangas' :	[13.8220744,121.0756147] ,
 'KooPinoy SIDC Savings and Loan  - Pili, Camarines Sur' :	[13.562363434615776, 123.28017077183426] ,
 //ATF Branches
 'SIDC Agritrading and Financing Bongabong': [12.6864, 121.2628],
 'SIDC Agritrading and Financing San Miguel Bulacan': [15.1634, 120.9663],
 'SIDC Agritrading and Financing Guimba': [15.6560, 120.7903],
 'SIDC Agritrading and Financing Calapan': [13.3624, 121.1456],
 'SIDC Agritrading and Financing Makato': [11.7111, 122.3000],
 'SIDC Agritrading and Financing Pila': [14.2931, 121.0529],
 'SIDC Agritrading and Financing Libmanan': [13.6717, 122.8090],
 'SIDC Agritrading and Financing Polangui': [13.3363, 123.4250],
 'SIDC Agritrading and Financing Tigaon': [13.6202, 123.3872],
 'SIDC Agritrading and Financing Talavera I': [15.7056, 120.8969],
 'SIDC Agritrading and Financing San Jose': [12.3581, 121.0427],
 'SIDC Agritrading and Financing Jaen': [15.3383, 120.9093],
 'SIDC Agritrading and Financing Pili': [13.5857, 123.2310],
 'SIDC Agritrading and Financing Tanay': [14.4927, 121.2860],
 'SIDC Agritrading and Financing Mayantoc': [15.6266, 120.3705],
 'SIDC Agritrading and Financing Victoria': [13.1804, 121.2824],
 'Feedmill': [13.8083,121.0705],
 'Layer Farm ': [13.8006,121.1317],
 'Pig Farm, Hog Selling Pen, Artificial Insemination': [13.8089,121.0904],
 'Contract Growing, Communal Farm': [13.8196,121.0838],
 'Green Farm (KoopLikas)': [13.7957,121.0819],
 'Ricemill': [13.0954,121.3451],
 'Palawan Feedmill': [9.2802,118.1527],
 'Dolores Quezon - feeds Outlet': [14.0200,121.3645],
 //Coopmarts and OSS
 'Coop Supermart Sorosoro (Coop Supermart)' : [13.810213, 121.089513] ,
 '1 Click mart Ilaya' : [13.815998, 121.089053] ,
 '1 Click mart Petron Balagtas' : [13.790300, 121.069542] ,
 'Coop mart Pallocan' : [13.753855, 121.066653] ,
 'Coop mart Conde (SIDC Multi purpose Cooperative)' : [13.735027, 121.108732] ,
 'Coop mart Pinamucan ( Sorosoro Ibaba Development Cooperative)' : [13.694419, 121.051803] ,
 'Coop mart San Miguel (SIDC Cooperative)' : [13.716046, 121.114080] ,
 'One Stop Shop Tabangao' : [13.714154, 121.062197] ,
 'One Stop Shop Sta. Rita' : [13.777048, 121.042585] ,
 'Coop mart Tinga (Coopmart)' : [13.792026, 121.083566] ,
 'Coop mart Mahacot ' : [13.773893, 121.120303] ,
 'Coop mart Balanga (Coopmart)' : [13.808980, 121.139720] ,
 'Coop mart Bauan (SIDC Bauan)' : [13.795415, 121.002412] ,
 'One Stop Shop Calaca' : [13.929142, 120.813110] ,
 'Coop Supermart Rosario' : [13.840445, 121.203160] ,
 'One Stop Shop San Juan' : [13.824888, 121.395848] ,
 'One Stop Shop Candelaria ( SIDC One Stop Shop Candelaria)' : [13.927278, 121.426142] ,
 'One Stop Shop Alaminos (SIDC Alaminos One Stop Shop)' : [14.065019, 121.246013] ,
 'Coopmart Alaminos' : [14.044765, 121.252200] ,
 'One Stop Shop Alabat (Sorosoro Ibaba Development Cooperative)' : [14.101703, 122.012660] ,
 'One Stop Shop OSS San Mariano, San Pascual' : [13.848936, 121.048645] ,
 'One Stop Shop Talisay' : [14.094483, 121.040659] ,
 'Coop mart Matungao' : [13.097079, 121.389209] ,
 'SIDC Gas Pinamalayan (SIDC Gas)' : [13.043919, 121.469584] ,
 'Coop Supermart Bansud (Coop Supermart Bansud)' : [12.860399, 121.456284] ,
 'One Stop Shop Bongabong' : [12.748490, 121.489323] ,
 'One Stop Shop Jaen ( SIDC Jaen)' : [15.340604, 120.909225] ,
 'One Stop Sho Zaragoza' : [15.449112, 120.793782] ,
 'One Stop Shop San Leonardo' : [15.365587, 120.963865] ,
 'One Stop Shop Calabanga' : [13.708422, 123.216297] ,
 'One Stop Shop Pili' : [13.561854837335241, 123.27966865471825] ,
 'One Stop Shop Jamindan ( SIDC One Stop Shop Jamindan)' : [11.410049, 122.510919] ,
 'One Stop Shop Bacolod' : [10.604594, 122.920942] ,
 'One Stop Shop President Roxas (SIDC Coop)' : [11.431701, 122.927698] ,
 'One Shop Stop Tapaz' : [11.264097, 122.534164] ,
 'One Stop Shop Palawan' : [9.269647, 118.407793] ,
 'Coopmart Jr. Alangilan' : [13.778667, 121.067083] ,
 'One Stop Shop Tiaong' : [13.957179344787916, 121.32071182178612] ,
 'One Stop Shop Antipolo del Sur Lipa' : [13.914365, 121.182976] ,
 'Coop Supermart Mahabang Parang' : [13.8220744,121.0756147] ,
 'SIDC BICOL Sales Warehouse and Office' : [13.562363434615776, 123.28017077183426] ,
 'Coopmart Jr. Dumantay' : [13.7654516, 121.1040642] ,
 'Coopmart Jr. Balagtas' : [13.799184284279693, 121.07028760031747] ,
 'Coopmart Talaibon' : [13.824677, 121.134854] ,
 'Coopmart Jr. Mabini, Lipa' : [13.890267869542633, 121.15092389806162] ,
 'CoopSupermart Gloria' : [12.970036, 121.477824] ,
 'Coopmart Jr. Agoncillo' : [13.9344870, 120.9280016] ,
 'OSS Taysan San Jose' : [13.882365, 121.099579] ,
 'OSS Tuy' : [14.019376, 120.727492] ,
 'One Stop Shop Sariaya' : [13.906693, 121.490355] ,
 'One Stop Shop Gulod' : [13.759051, 121.074832] ,
 'Coopmart Jr. Sto Nino Batangas City' : [13.702363259344851, 121.09477822523249] ,
 'OSS Dela Paz' : [13.633766504846529, 121.1271846895322] ,
 'OSS San Agustin' : [13.82975644578103, 121.15876981459813] ,
 'OSS Sta Teresita' : [13.879232431541901, 120.96546439872759] ,
 'Coopmart Mahacot' : [13.77581302284706, 121.11568352217198] ,
 'One Stop Shop Tulo' : [13.753683030469274, 121.12833780583868] ,
 'Coopmart Jr. Sto Nino San Pascual' : [13.796325372544858, 121.02006317127591] ,
 'One Stop Shop Bayanan' : [13.808100411035294, 121.03554843157998] ,
 'Coopmart Jr Cuenca' : [13.897457, 121.050860] ,
 'One Stop Shop New Buswang Kalibo Aklan' : [11.710861, 122.370315] ,
 'Coopmart Calatagan' : [13.831945803186642, 120.63239703308702] ,
 'One Stop Shop Sampaguita' : [13.916384, 121.144265] ,
 'OSS Mabini' : [13.752103, 120.940440] ,
 'One Stop Shop San Roque San Jose' : [12.358300, 121.059596] ,
 'OSS Libjo' : [13.735943,121.072211] ,
 'Coop mart Jr. Bagong Pooc' : [13.941558117580682, 121.11066383649089] ,
 'One Stop Shop Dagatan Taysan' : [13.734957287237059, 121.199576854729] ,
 'One Stop Shop Lonoy' : [11.510107346089667, 122.74468358591048] ,
 'One Stop Shop Victoria' : [13.16886292984829, 121.27878548597874] ,
 'One Stop Shop Socorro' : [13.055694874779734, 121.40974129580263] ,
 'One Stop Shop Magsaysay' : [12.31301199680064, 121.14672464735936] ,
 'SIDC Feeds Padre Garcia Outlet' : [13.87804448759807, 121.2155267657515] ,
 'SIDC One Stop Shop Calapan' : [13.374162746791123, 121.1657942967844] ,
 'SIDC One Stop Shop Calintaan' : [12.570455030205753, 120.94421521378088] ,
 'SIDC Feeds Outlet El Nido' : [11.200180510406183, 119.42495872379065] ,
 'SIDC Feeds Outlet Lucban' : [14.1257838,121.5613269] ,
 'SIDC Feeds Outlet Catanauan' : [13.587210,122.330221] ,
}

class BranchesMap extends React.Component {
  componentDidMount() {
    if (typeof window !== undefined) {
      this.renderMap()
    }
  }

  renderMap = () => {
    // configuration to overcome issue on the marker
    let L = require('leaflet')
    let DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    // implementation
    let branchesMap = L.map('branches-map').setView([12.651,121.509], 6)

    setTimeout(() => {
      console.log('invalidate')
      branchesMap.invalidateSize()
    }, 1000)

    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
        attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        tileSize: 512,
        maxZoom: 18,
        zoomOffset: -1,
        id: 'mapbox/streets-v11',
        accessToken: process.env.GATSBY_SITE_MAP_TOKEN
    }).addTo(branchesMap);

    for (let key in branches) {
        if (branches.hasOwnProperty(key)) {
            let marker = L.marker(branches[key]).addTo(branchesMap);
            marker.bindPopup(`<b>${key} branch</b>`)
        }
    }
  }

  render() {
    return (
      <Container>
        <div id="branches-map"></div>
      </Container>
    )
  }
}

export default BranchesMap
