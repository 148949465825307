import React from 'react'
import PropTypes from 'prop-types'
import './vendors.js'
import MessengerCustomerChat from 'react-messenger-customer-chat';
import DataPrivacyModal from './Sections/DataPrivacyModal'

class Root extends React.Component {
  componentDidMount() {
  }
    render() {
        return (
            <div id="root">
                { this.props.children }
                  <MessengerCustomerChat pageId="317631091910844" appId="454563365400103" htmlRef="https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"/>
                  <DataPrivacyModal/>
            </div>
        )
    }
}

Root.propTypes = {
    children: PropTypes.node.isRequired,
  }

export default Root
