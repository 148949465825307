import React from 'react'

const Container = ({ children, padding = 5, paddingBottom = null, paddingTop = null, center = true, dark = false, className = '', ...otherProps }) => {
    let classNames = ''
    if (paddingBottom !== null || paddingTop !== null) {
        classNames = `${paddingTop !== null ? 'pt-' + paddingTop: ''} ${paddingBottom !== null ? 'pb-' + paddingBottom: ''} ${center ? 'text-center': ''} ${dark ? 'text-dark' : ''} ${className}`
    }
    else {
        classNames = `py-${padding} ${paddingTop !== null ? 'pt-' + paddingTop: ''} ${paddingBottom !== null ? 'pb-' + paddingBottom: ''} ${center ? 'text-center': ''} ${dark ? 'text-dark' : ''} ${className}`
    }
    return (
        <div className={classNames} { ...otherProps }>
            { children }
        </div>
    )
}

export default Container